<template>
	<div class="page df jcc">
		<div style="width: 30%;" class="df jcc fdc aic">
			<a-divider>
				<div class="df aic jcc">
					<a-icon :style="{color:'#52c41a',fontSize:'40px'}" type="wechat" />
					<span>微信支付</span>
				</div>
			</a-divider>
			<img width="200" height="200" :src="pay">
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				pay: null,
				timer:null
			}
		},
		mounted() {
			let {
				pay,
				order_id
			} = this.$route.query
			this.pay = 'http://chaofan.fqcp009.top/uploads/qrcode/' + pay
			this.queryOrderPay(order_id)
		},
		methods: {
			async queryOrderPay(order_id) {
				this.timer = setInterval(async () => {
					let {
						result
					} = await this.$api.queryOrderPay(order_id)
					if (result) {
						clearInterval(this.timer)
						this.$go(`/result?order_id=${order_id}`)
					}
				}, 3000)
			},
		},
		destroyed() {
			clearInterval(this.timer)
		}
	}
</script>

<style lang="scss" scoped>
	@import '@/common/scss/turn.scss';

	.page {
		padding-bottom: 100px;
	}
</style>
